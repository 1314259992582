import { useEffect, useState } from 'react';

export const useNetworkConnection = () => {
  const [online, setOnline] = useState(typeof window === 'undefined' ? true : (navigator.onLine ?? true));
  useEffect(() => {
    const onEvent = () => setOnline(isOnline());
    window.addEventListener('online', onEvent);
    window.addEventListener('offline', onEvent);
    return () => {
      window.removeEventListener('online', onEvent);
      window.removeEventListener('offline', onEvent);
    };
  }, []);
  return online;
};

export const isOnline = () => navigator.onLine;
