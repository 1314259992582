import { styled } from '@mui/material/styles';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import { useAtom } from 'jotai';
import { useCallback, useEffect } from 'react';
import { useEditContext } from '../EditContext';
import { versionChecker } from './VersionChecker';
import { releaseTypeAtom } from './store';
import { reloadJavascriptApp } from './helpers';

const StyledAlert = styled(Alert)(({ theme, severity }) => ({
  paddingRight: '32px',
  button: {
    backgroundColor: severity === 'error' ? 'rgb(255 212 212)' : 'rgb(238 221 198)',
  },
}));

const UpdateButton = () => {
  const onClickUpdate = useCallback(() => {
    reloadJavascriptApp();
  }, []);
  return (
    <Button color="inherit" variant="text" size="small" onClick={onClickUpdate}>
      Update
    </Button>
  );
};

export const UpdateAlert = () => {
  const [releaseType, setReleaseType] = useAtom(releaseTypeAtom);
  const { editing } = useEditContext();

  useEffect(() => {
    const onUpdate = ({ releaseType }: { releaseType: string }) => {
      setReleaseType(releaseType);
    };
    versionChecker.on('updateAvailable', onUpdate);

    return () => {
      versionChecker.off('updateAvailable', onUpdate);
    };
  }, [setReleaseType]);

  if (editing || !releaseType?.length) {
    return null;
  }

  const severity = releaseType === 'major' ? 'error' : 'warning';

  return (
    <StyledAlert severity={severity} action={<UpdateButton />}>
      {`There's a new version of the app available. Please update to the latest version.`}
    </StyledAlert>
  );
};
