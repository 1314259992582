import { VERSION } from '@/components/Providers/config';
import EventEmitter from 'events';
import * as semver from 'semver';
import { FETCH_INTERVAL_MS, MIN_FETCH_INTERVAL_MS_FOR_GRAPHQL_ERRORS } from './constants';
import { StatusResponse } from './types';
import { analytics } from '../Analytics/analytics';

/**
 * Check for updates to the app
 * - Every hour
 * - Every time the user focuses the app after having blurred it
 * - Every time we receive an error in a graphql response
 */
class VersionChecker extends EventEmitter {
  constructor() {
    super();
    if (typeof window !== 'undefined') {
      // only execute this block in the browser
      this.startInterval();
      // recheck when app is in background and then refocused
      window.addEventListener('focus', this.onWindowFocus);
      window.addEventListener('blur', this.onWindowBlur);
    }
  }

  currentVersion = VERSION; // NOTE: app build version
  latestVersion?: string;
  updateAvailable = false;
  releaseType?: string;
  private lastChecked?: number;
  private lastCheckedDueToGraphQLError?: number;
  private refreshIntervalId?: ReturnType<typeof setInterval>;

  private onWindowFocus = () => this.startInterval(true);
  private onWindowBlur = () => this.stopInterval();

  private startInterval = (checkNowToo = false) => {
    if (checkNowToo) {
      this.checkVersion();
    }
    this.stopInterval();
    this.refreshIntervalId = setInterval(this.checkVersion, FETCH_INTERVAL_MS);
  };

  private stopInterval = () => {
    clearInterval(this.refreshIntervalId);
    this.refreshIntervalId = undefined;
  };

  checkDueToGraphQLError = async () => {
    if (
      this.lastCheckedDueToGraphQLError &&
      Date.now() - this.lastCheckedDueToGraphQLError < MIN_FETCH_INTERVAL_MS_FOR_GRAPHQL_ERRORS
    ) {
      return;
    }
    this.lastCheckedDueToGraphQLError = Date.now();
    return this.checkVersion();
  };

  checkVersion = async () => {
    // make sure we never call this more than twice per second
    if (this.lastChecked && Date.now() - this.lastChecked < 500) {
      return this.updateAvailable;
    }

    this.lastChecked = Date.now();

    const response = await fetch('/api/status');
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    const { version } = (await response.json()) as StatusResponse;
    const [latestVersion, flag] = version.split('-');
    const currentVersion = this.currentVersion.split('-')[0];
    const releaseType = semver.diff(latestVersion, currentVersion);
    this.latestVersion = latestVersion;
    this.updateAvailable = semver.gt(latestVersion, currentVersion);

    if (releaseType) {
      this.releaseType = releaseType;
    }
    if (!flag && this.updateAvailable) {
      this.emit('updateAvailable', {
        updateAvailable: this.updateAvailable,
        releaseType: this.releaseType,
        latestVersion,
      });
    }

    analytics.setContext('server-version', latestVersion);

    return this.updateAvailable;
  };
}

export const versionChecker = new VersionChecker();
