import NextLink from 'next/link';
import { Link as MuiLink, LinkProps as MuiLinkProps } from '@mui/material';

interface LinkProps extends MuiLinkProps {}

export const Link = ({ children, ...props }: LinkProps) => {
  return (
    <MuiLink component={NextLink} {...props}>
      {children}
    </MuiLink>
  );
};

type LotLinkProps = Omit<LinkProps, 'href'> & { lotId: number };
type PenLinkProps = Omit<LinkProps, 'href'> & { penId: number };
type ContactLinkProps = Omit<LinkProps, 'href'> & { contactId: number };

export const LotLink = ({ lotId, ...props }: LotLinkProps) => {
  return <Link href={`/dashboard/lots/${lotId}`} {...props} />;
};

export const PenLink = ({ penId, ...props }: PenLinkProps) => {
  return <Link href={`/dashboard/pens/${penId}`} {...props} />;
};

export const ContactLink = ({ contactId, ...props }: ContactLinkProps) => {
  return <Link href={`/dashboard/contacts/${contactId}`} {...props} />;
};

export const TableLink = ({ sx, ...props }: LinkProps) => {
  return <Link sx={{ color: 'inherit', ...sx }} {...props} />;
};

export const LotTableLink = ({ sx, ...props }: LotLinkProps) => {
  return <LotLink sx={{ color: 'inherit', ...sx }} {...props} />;
};

export const PenTableLink = ({ sx, ...props }: PenLinkProps) => {
  return <PenLink sx={{ color: 'inherit', ...sx }} {...props} />;
};

export const ContactTableLink = ({ sx, ...props }: ContactLinkProps) => {
  return <ContactLink sx={{ color: 'inherit', ...sx }} {...props} />;
};
