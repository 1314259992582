export const ERROR_MESSAGES = {
  EDIT_IN_PROGRESS: 'Editing in progress',
  ERROR_MESSAGE_DEFAULT: 'Something went wrong. Please try again later.',
  ERROR_MESSAGE_NETWORK: 'Poor network connection. Please try again later.',
  ERROR_MESSAGE_OUTDATED_VERSION: 'You are using an outdated version of Redbook.',
  ERROR_MESSAGE_CLICK_TO_UPDATE: 'Click here to update',
};

export const ONBOARDING_NOTE = 'Automated Record Created via Redbook Onboarding';
export const CHANGES_SAVED_MESSAGE = 'Changes successfully saved';
