import { useGetPenDropDetailsDialogQuery } from '@/web-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import gql from 'graphql-tag';
import { useCallback, useMemo, useState } from 'react';
import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import { DialogContainer } from '../Dialog';
import ModalManager, { useModal } from '@ebay/nice-modal-react';
import { PenDropsListProps } from '@/components/CallFeedTab/types';
import { DETAILS_QUERY_LIMIT } from '@/components/CallFeedTab/constants';
import { PenDropsList } from '@/components/CallFeedTab/PenDropsList';
import { LoadingScreen } from '@/components/LoadingScreen';
import { ErrorScreen } from '@/components/ErrorScreen';
import { PenHistoryGraph } from '@/components/CallFeedTab/PenHistoryGraph';
import { PenDropDetailsTable } from '@/components/CallFeedTab/PenDropDetailsTable';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { createMap } from '@/common/utils/map';
import { useWatch } from 'react-hook-form';
import { callFeedKvStore } from './callFeedKvStore';
import { PenPicker } from '@/components/CallFeedTab/PenPicker';
import { KeyboardReturn } from '@mui/icons-material';
import { showCallFeedSettingsModal } from '@/components/CallFeedTab/CallFeedSettingsModal';
import SettingsIcon from '@mui/icons-material/Settings';
import { useIsPhoneSize } from '@/components/hooks/useResponsive';
import { BunkScorePicker } from '@/components/CallFeedTab/BunkScorePicker';
import Typography from '@mui/material/Typography';
import { Header, InDialogHeader } from '@/components/CallFeedTab/PenDropsListHeader';

gql`
  query GetPenDropDetailsDialog($penId: Int!, $lotId: Int, $limit: Int!) {
    getPenDropDetails(penId: $penId, lotId: $lotId, limit: $limit) {
      rations {
        id
        name
        color
      }
      penDropAtDates {
        date
        bunkScore
        numHeads
        rationFedPerHead {
          rationId
          fedPerHead {
            asFedLbs
            dryMatterLbs
          }
        }
        fedLbsOnDate
        fedPerHead {
          asFedLbs
          dryMatterLbs
        }
        calledLbsOnDate
        calledLbsChange
        calledPerHead {
          asFedLbs
          dryMatterLbs
        }
        historicalMaxFedPerHead {
          asFedLbs
          dryMatterLbs
        }
        projectedCurrentWeightPerHeadLbs
        numDeads
        numAdds
        numSales
        numMoves
        numTreated
      }
    }
  }
`;

interface ModalProps
  extends Pick<
    PenDropsListProps,
    'pens' | 'rations' | 'rationIngredientsMap' | 'isTodaySelected' | 'selectedDate' | 'formMethods'
  > {
  penIndex: number;
  formRef?: React.RefObject<HTMLFormElement>;
}

export const showPenDropDetailsModal = (props?: ModalProps) => ModalManager.show(PenDropDetailsModal, props);

const PenDropDetailsModal = ModalManager.create<ModalProps>(
  ({
    pens,
    penIndex: originalPenIndex,
    rations,
    rationIngredientsMap,
    selectedDate,
    isTodaySelected,
    formMethods,
    formRef,
  }) => {
    const isPhoneSize = useIsPhoneSize();
    const [penIndex, setPenIndex] = useState(() => originalPenIndex);
    const pen = pens[penIndex];
    const [selectedPenLotId, setSelectedPenLotId] = useState<number>(pen.penLots?.[0]?.id ?? 0);
    const modal = useModal();
    const { control, setValue, formState } = formMethods;
    const [graphShowDmi, _pens] = useWatch({ control, name: [`graphShowDmi`, `pens`] });
    const bunkScore = _pens?.[penIndex]?.bunkScore;

    const selectedPenLot = useMemo(() => {
      const pl = pen.penLots?.find((lot) => lot.id === selectedPenLotId);
      if (pl) {
        return pl;
      }

      setSelectedPenLotId(pen.penLots?.[0]?.id ?? 0);
    }, [pen.penLots, selectedPenLotId]);

    const [{ fetching, data, error }] = useGetPenDropDetailsDialogQuery({
      variables: {
        penId: pen?.id,
        lotId: selectedPenLot?.lot?.id,
        limit: DETAILS_QUERY_LIMIT,
      },
    });

    const graphData = useMemo(
      () => [...(data?.getPenDropDetails?.penDropAtDates ?? [])].sort((a, b) => (a.date < b.date ? -1 : 1)),
      [data?.getPenDropDetails]
    );
    const tableData = useMemo(() => {
      const items = [...(data?.getPenDropDetails?.penDropAtDates ?? [])].sort((a, b) => (a.date < b.date ? 1 : -1));
      items.length = Math.min(items.length, 10);
      return items;
    }, [data?.getPenDropDetails]);

    const feedHistoryRations = useMemo(() => {
      return data?.getPenDropDetails?.rations ?? [];
    }, [data?.getPenDropDetails]);

    const feedHistoryRationMap = useMemo(() => {
      return createMap(data?.getPenDropDetails?.rations ?? [], (r) => r.id);
    }, [data?.getPenDropDetails]);

    const updateGraphShowDmi = useCallback(
      (graphShowDmi: boolean) => {
        setValue(`graphShowDmi`, graphShowDmi, { shouldDirty: false });
        callFeedKvStore.set({ graphShowDmi });
      },
      [setValue]
    );

    const exit = (ref?: React.RefObject<HTMLFormElement>) => {
      if (formMethods.formState.isDirty && ref) {
        ref?.current?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
      }
      modal.remove();
    };

    return (
      <Dialog onClose={modal.remove} open={modal.visible} fullScreen>
        <DialogContainer data-testid="pen-drop-details-dialog" sx={{ minHeight: '100%', py: 0, px: 1 }}>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            position="relative"
          >
            <Box display="flex" alignItems="center">
              <PenPicker pens={pens} penIndex={penIndex} setPenIndex={setPenIndex} />
              <Typography sx={{ fontSize: 14, color: 'secondary.main' }}>
                Head: <b>{pens[penIndex].numHeads}</b>
              </Typography>
              <BunkScorePicker
                onChange={(value) => setValue(`pens.${penIndex}.bunkScore`, value, { shouldDirty: true })}
                value={bunkScore}
              />
            </Box>

            <Box
              display={{ xs: 'none', md: 'flex' }}
              alignItems="center"
              position={{ xs: 'static', lg: 'absolute' }}
              left="50%"
              width={{ xs: 'auto', lg: 330 }}
              marginLeft={{ xs: 0, lg: '-115px' }}
              justifyContent="center"
            >
              <Button
                variant="text"
                data-testid="call-feed-pen-previous-pen"
                disabled={penIndex === 0}
                startIcon={<NavigateBeforeIcon fontSize="small" />}
                onClick={() => setPenIndex((prev) => prev - 1)}
                sx={{ color: 'customGrey.main', fontWeight: 500, fontSize: 13 }}
              >
                {isPhoneSize ? '' : 'Previous Pen'}
              </Button>
              <Button
                variant="text"
                data-testid="call-feed-pen-next-pen"
                disabled={penIndex === pens.length - 1}
                endIcon={<NavigateNextIcon fontSize="small" />}
                onClick={() => setPenIndex((prev) => prev + 1)}
                sx={{ color: 'customGrey.main', fontWeight: 500, fontSize: 13 }}
              >
                {isPhoneSize ? '' : 'Next Pen'}
              </Button>
            </Box>
            <Box>
              <Button
                variant="text"
                startIcon={<SettingsIcon />}
                onClick={() => showCallFeedSettingsModal({ defaultTabIndex: 1 })}
              >
                {isPhoneSize ? '' : 'Settings'}
              </Button>
              <Button variant="text" startIcon={<KeyboardReturn />} onClick={() => exit(formRef)}>
                {(() => {
                  if (isPhoneSize) return '';
                  return formState.isDirty ? 'Save & Exit' : 'Exit';
                })()}
              </Button>
            </Box>
          </Box>

          <Header>
            {(pen.penLots ?? []).length > 0 && (
              <InDialogHeader pen={pen} selectedPenLotId={selectedPenLotId} setSelectedPenLotId={setSelectedPenLotId} />
            )}
          </Header>

          <Box sx={{ md: { height: 188 }, mb: 2 }}>
            <PenDropsList
              key={`pens.${penIndex}`}
              pens={pens}
              penIndex={penIndex}
              rations={rations}
              rationIngredientsMap={rationIngredientsMap}
              inDialog
              isTodaySelected={isTodaySelected}
              selectedDate={selectedDate}
              formMethods={formMethods}
            />
          </Box>

          {fetching && <LoadingScreen />}
          {!fetching && error && <ErrorScreen />}
          {!fetching && (
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <PenDropDetailsTable data={tableData} rations={feedHistoryRations} rationMap={feedHistoryRationMap} />
              </Grid>
              <Grid item xs={12} md={6}>
                <Box display="flex" flexDirection="column" sx={{ minHeight: 300, height: { xs: 450, md: '100%' } }}>
                  <Box flex={1}>
                    <PenHistoryGraph
                      penDropAtDates={graphData}
                      rationMap={feedHistoryRationMap}
                      showDmi={graphShowDmi}
                    />
                  </Box>
                  <Box display="flex" justifyContent="center">
                    <FormControlLabel
                      data-testid={`call-feed-pen-detailed-graph-dmi-checkbox`}
                      control={
                        <Checkbox
                          checked={graphShowDmi}
                          onChange={(e) => updateGraphShowDmi(Boolean(e.target.checked))}
                        />
                      }
                      label="DMI"
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          )}
        </DialogContainer>
      </Dialog>
    );
  }
);
