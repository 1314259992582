import '@/common/utils/date';

/**
 * Return the pen lots that are active on the given date
 */
export const filterPenLotsForDate = <T extends { fromDate: Date; toDate: Date | null }>(
  penLots: T[] | null | undefined,
  dateTime: Date
): T[] => {
  return (penLots ?? []).filter((penLot) => {
    return dateTime >= penLot.fromDate && (penLot.toDate == null || dateTime < penLot.toDate);
  });
};

/**
 * Return the pen lots that were active just before the given date
 */
export const filterPenLotsJustBeforeDate = <T extends { fromDate: Date; toDate: Date | null }>(
  penLots: T[] | null | undefined,
  dateTime: Date
): T[] => {
  // same logic as `filterPenLotsForDate` but inequalities are reversed
  return (penLots ?? []).filter((penLot) => {
    return dateTime > penLot.fromDate && (penLot.toDate == null || dateTime <= penLot.toDate);
  });
};

/**
 * Return the pen lots that are active within the given date range
 */
export const filterPenLotsForDateRange = <T extends { fromDate: Date; toDate: Date | null }>({
  penLots,
  fromDate,
  toDate,
}: {
  penLots: T[] | null | undefined;
  fromDate?: Date | null;
  toDate?: Date | null;
}): T[] => {
  return (penLots ?? []).filter((penLot) => {
    if (fromDate && penLot.toDate && penLot.toDate < fromDate) {
      return false;
    }
    if (toDate && penLot.fromDate > toDate) {
      return false;
    }
    return true;
  });
};
