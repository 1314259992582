import { CallBy, CallFeedUIState } from './types';
import { PenLot } from '@/web-types';
import { formatNumber } from '@/common/utils/format';
import { sexLabel } from '@/common/dictionaries/lot';
import { emDash } from '@/components/Typography/emDash';
import { formatDate, formatProjectedWeightLbs } from '@/components/helpers/format';
import { Subset } from '@/common/types/subset';
import { getEstimatedSaleDateString } from '@/components/Lots/helpers';
import dayjs from 'dayjs';

export const CALLED_LBS_DELTA = 100;
export const DETAILS_QUERY_LIMIT = 19;

export const defaultKvStore: CallFeedUIState = {
  callBy: CallBy.DMI_PER_HEAD,
  graphShowDmi: false,
};

export interface SortableLotInfoBlock {
  label: string;
  shortLabel: string;
  renderValue: (pl: Subset<PenLot>) => string;
}

export const sortableLotInfoBlocks: SortableLotInfoBlock[] = [
  { label: 'Head', shortLabel: 'Head', renderValue: (pl: Subset<PenLot>) => formatNumber(pl?.numHeads) },
  {
    label: 'Sex',
    shortLabel: 'Sex',
    renderValue: (pl: Subset<PenLot>) => (pl?.lot?.sex ? sexLabel(pl?.lot?.sex) : emDash),
  },
  {
    label: 'In Date',
    shortLabel: 'In Date',
    renderValue: (pl: Subset<PenLot>) => (pl?.lot?.processedOn ? formatDate(pl?.lot?.processedOn) : emDash),
  },
  {
    label: 'Estimated Sale Date',
    shortLabel: 'Est. Sale Date',
    renderValue: (pl: Subset<PenLot>) =>
      getEstimatedSaleDateString({
        estimatedSaleWeightPerHeadLbs: pl?.lot?.estimatedSaleWeightPerHeadLbs,
        estimatedAverageDailyGain: pl?.lot?.estimatedAverageDailyGain,
        inWeightPerHeadLbs: pl?.lot?.numHeadsIn ? pl?.lot?.inWeightLbs! / pl?.lot?.numHeadsIn! : 0,
        processedOn: pl?.lot?.processedOn,
      }) ?? emDash,
  },
  {
    label: 'In Weight / Head',
    shortLabel: 'In Weight',
    renderValue: (pl: Subset<PenLot>) =>
      pl?.lot ? formatProjectedWeightLbs(pl?.lot.inWeightLbs! / pl?.lot.numHeadsIn!) : emDash,
  },
  {
    label: 'Estimated Weight / Head',
    shortLabel: 'Est. Weight',
    renderValue: (pl: Subset<PenLot>) =>
      pl?.lot?.projectedCurrentWeightPerHeadLbs
        ? formatProjectedWeightLbs(pl?.lot.projectedCurrentWeightPerHeadLbs)
        : emDash,
  },
  { label: 'Deads', shortLabel: 'Deads', renderValue: (pl: Subset<PenLot>) => formatNumber(pl?.lot?.deathLossCount) },
  {
    label: 'Cattle Treated',
    shortLabel: 'Treats',
    renderValue: (pl: Subset<PenLot>) => formatNumber(pl?.lot?.numHeadsTreated),
  },
  {
    label: 'Sold',
    shortLabel: 'Sold',
    renderValue: (pl: Subset<PenLot>) => formatNumber(pl?.lot?.totalSalesNumHeads),
  },
  { label: 'Days on Feed', shortLabel: 'DOF', renderValue: (pl: Subset<PenLot>) => formatNumber(pl?.lot?.daysOnFeed) },
  {
    label: 'Days in Pen',
    shortLabel: 'DIP',
    renderValue: (pl: Subset<PenLot>) => {
      const fromDate = dayjs.tz(pl?.fromDate)!;
      const toDate = pl?.toDate ? dayjs.tz(pl?.toDate) : null;
      return String(toDate ? toDate.diff(fromDate, 'days') : dayjs.tz().diff(fromDate, 'days'));
    },
  },
  {
    label: 'ADG',
    shortLabel: 'ADG',
    renderValue: (pl: Subset<PenLot>) => (pl?.lot?.averageDailyGain ? formatNumber(pl?.lot?.averageDailyGain) : emDash),
  },
  { label: 'Source', shortLabel: 'Source', renderValue: (pl: Subset<PenLot>) => pl?.lot?.source ?? emDash },
  {
    label: 'Owner',
    shortLabel: 'Owner',
    renderValue: (pl: Subset<PenLot>) => pl?.lot?.lotOwners?.map((owner) => owner?.contact?.name).join(', ') ?? emDash,
  },
];

export const detailedViewColumns = [
  'Date',
  'Head',
  'Ration',
  'Bunk Score',
  'Call AF',
  'Change AF',
  'As Fed',
  'AF/HD',
  'DMI/HD',
  'Call AF/HD',
  'Fed AF/HD',
  'Call DMI/HD',
  'Fed DMI/HD',
  'Est. Weight',
  'DM % BW',
  'Variance AF',
];
