import FeedingLoadDropTable from '@/components/BuildLoadsTab/FeedingLoadDropTable';
import FeedingLoadIngredientTable from '@/components/BuildLoadsTab/FeedingLoadIngredientTable';
import { ArrowDownwardButton, ArrowUpwardButton } from '@/components/BuildLoadsTab/Icons';
import { BuildLoadSectionProps, BuildLoadsFormType, FeedingLoadDropTableRow } from '@/components/BuildLoadsTab/types';
import { BoxTablePadding, BoxTablePaddingGray } from '@/components/Feeding/styledComponents';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useCallback, useMemo } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { styled } from '@mui/material/styles';
import { useIsPhoneSize } from '@/components/hooks/useResponsive';
import { useOrgSettings } from '@/components/OrgSettings/OrgSettings';
import { roundToNearestTenWithOrgSettings } from '@/components/CallFeedAmount/helpers';
import { formatNumber } from '@/components/helpers/format';
import { showConfirmationModal } from '@/components/ConfirmationModal/ConfirmationModal';
import { RationWithColorTag } from '@/components/Inventory/RationWithColorTag';
import { Subset } from '@/common/types/subset';
import { Ration } from '@/web-types';

const BuildLoadsSubHeaderContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  background: theme.palette.grey[50],
  borderRadius: theme.spacing(2),
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    borderRadius: theme.spacing(1),
    '.MuiSvgIcon-root': {
      fontSize: '16px',
    },
  },
}));

const Container = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(1),
  [theme.breakpoints.down('md')]: {
    '.MuiSvgIcon-root': {
      fontSize: '16px',
    },
  },
}));
const LineBreak = styled(Box)(({ theme }) => ({
  margin: `${theme.spacing(3)} 0`,
  height: '1px',
  background: theme.palette.grey[300],
}));

const BuildLoadsAddDropButton = styled(Button)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  marginTop: theme.spacing(1),
  [theme.breakpoints.down('md')]: {
    marginTop: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
  },
  [theme.breakpoints.down('sm')]: {
    marginTop: theme.spacing(1),
  },
}));

const BuildLoadsActions = styled(Stack)(({ theme }) => ({
  alignItems: 'center',
}));

const BuildLoadsSubHeader = ({
  loadName,
  ration,
  loadIndex,
  loadLength,
  disableDeleteLoad = false,
  drops,
  disableSorting = false,
  onDelete,
  onMoveLoadUp,
  onMoveLoadDown,
}: {
  loadName: string;
  ration?: Subset<Ration> | null;
  loadIndex: number;
  loadLength: number;
  disableDeleteLoad?: boolean;
  drops: FeedingLoadDropTableRow[];
  disableSorting?: boolean;
  onDelete: () => void;
  onMoveLoadUp: () => void;
  onMoveLoadDown: () => void;
}) => {
  const isPhoneSize = useIsPhoneSize();
  return (
    <BuildLoadsSubHeaderContainer>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'end',
        }}
      >
        <Typography
          component="span"
          color="primary"
          sx={{
            fontWeight: 700,
            fontSize: '16px',
          }}
        >
          {loadName}
        </Typography>

        <Box
          sx={{
            ml: 3,
            fontWeight: 500,
            fontSize: isPhoneSize ? null : '14px',
          }}
        >
          <RationWithColorTag ration={ration} />
        </Box>
      </Box>

      <BuildLoadsActions direction="row" spacing={isPhoneSize ? 1 : 2}>
        <Typography component="span" sx={{ fontWeight: 700, fontSize: '16px' }}>
          Total: {formatNumber(drops.reduce((acc, drop) => acc + drop.calledLbs, 0))} lbs
        </Typography>
        <ArrowUpwardButton
          disabled={disableSorting || loadIndex === 0}
          data-testid="feeding-load-move-load-up-button"
          onClick={onMoveLoadUp}
        />
        <ArrowDownwardButton
          disabled={disableSorting || loadIndex === loadLength - 1}
          data-testid="feeding-load-move-load-down-button"
          onClick={onMoveLoadDown}
        />
        <IconButton
          data-testid="build-loads-delete-load-button"
          size="small"
          onClick={onDelete}
          disabled={disableDeleteLoad}
        >
          <DeleteIcon color={disableDeleteLoad ? 'inherit' : 'primary'} />
        </IconButton>
      </BuildLoadsActions>
    </BuildLoadsSubHeaderContainer>
  );
};

export default function BuildLoadSection({
  loadIndex,
  load,
  loadLength,
  disableDeleteLoad = false,
  disableDeleteDrop = false,
  disableAddDrop = false,
  disableSorting = false,
  onOpenAddDropDialog,
}: BuildLoadSectionProps): JSX.Element {
  const [{ settings: orgSettings }] = useOrgSettings();
  const isPhoneSize = useIsPhoneSize();
  const { control, setValue, watch } = useFormContext<BuildLoadsFormType>();
  const dropIdToLoadIdMap = watch('dropIdToLoadIdMap');
  const { remove: removeLoad, swap } = useFieldArray({ control, name: `loads` });
  const drops = useMemo(
    () =>
      (load.drops ?? []).map((drop) => ({
        id: drop.id,
        name: drop?.pen?.name,
        penDropIndex: drop?.penDropIndex || 0,
        calledLbs: roundToNearestTenWithOrgSettings(drop?.calledLbs ?? 0, orgSettings) || 0,
        fedLbs: drop?.fedLbs || 0,
      })),
    [load.drops, orgSettings]
  );

  const calledLbsTotal = drops.reduce((acc, drop) => acc + drop.calledLbs, 0);
  const ingredients = useMemo(
    () =>
      (load.drops?.[0]?.ration?.ingredients ?? []).map((ingredient) => ({
        id: ingredient.id,
        name: ingredient?.ingredient?.name,
        calledLbs: roundToNearestTenWithOrgSettings(
          ingredient?.percentOfRationAsFed * calledLbsTotal || 0,
          orgSettings
        ),
        loadedLbs: 0, // TODO: get from db
      })),
    [calledLbsTotal, load.drops, orgSettings]
  );

  const handleDelete = useCallback(
    () =>
      showConfirmationModal({
        title: `Delete Load ${loadIndex + 1}`,
        message: `Are you sure you want to delete Load ${loadIndex + 1}?`,
        confirmButton: 'Delete',
        onConfirm: () => {
          const updatedMap = { ...dropIdToLoadIdMap };
          load?.drops?.forEach((drop) => {
            delete updatedMap[drop.id];
          });
          setValue('dropIdToLoadIdMap', updatedMap);
          removeLoad(loadIndex);
        },
      }),
    [dropIdToLoadIdMap, loadIndex, load?.drops, removeLoad, setValue]
  );

  const handleMoveLoadUp = useCallback(() => {
    swap(loadIndex, loadIndex - 1);
  }, [loadIndex, swap]);
  const handleMoveLoadDown = useCallback(() => {
    swap(loadIndex, loadIndex + 1);
  }, [loadIndex, swap]);

  return (
    <>
      <BuildLoadsSubHeader
        loadName={`Load ${loadIndex + 1}`}
        ration={load.drops?.[0]?.ration}
        disableDeleteLoad={disableDeleteLoad}
        disableSorting={disableSorting}
        drops={drops}
        loadIndex={loadIndex}
        loadLength={loadLength}
        onDelete={handleDelete}
        onMoveLoadUp={handleMoveLoadUp}
        onMoveLoadDown={handleMoveLoadDown}
      />
      <Container>
        <Stack direction={isPhoneSize ? 'column' : 'row'} spacing={3}>
          <Box sx={{ flex: 5 }}>
            <BoxTablePadding>
              <FeedingLoadDropTable
                loadIndex={loadIndex}
                drops={drops}
                stage={load.stage}
                disableDeleteDrop={disableDeleteDrop}
                disableDeleteLoad={disableDeleteLoad}
              />
            </BoxTablePadding>

            <BuildLoadsAddDropButton
              data-testid="build-loads-add-drop-button"
              variant="outlined"
              startIcon={<AddIcon />}
              disabled={disableAddDrop}
              onClick={onOpenAddDropDialog(loadIndex)}
            >
              Add Drop
            </BuildLoadsAddDropButton>
          </Box>
          <BoxTablePaddingGray sx={{ flex: 4 }}>
            <FeedingLoadIngredientTable ingredients={ingredients} />
          </BoxTablePaddingGray>
        </Stack>
      </Container>
    </>
  );
}
